
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapMutations } from 'vuex';

@Component({
  name: 'organizationTable',
  props: ['events'],
  data() {
    return {
      ajaxCompleted: false,
      organizations: [],
    };
  },
  methods: {
    ...mapMutations('whereverConfig', ['setConfig']),
  },
})
export default class OrganizationTable extends Vue {
  ajaxCompleted!: boolean;

  organizations!: any[];

  setConfig: any;

  async getorganizations() {
    const response = await this.$utils.http().get('/api/v1/access/organizations');
    if (response.data.success) {
      this.ajaxCompleted = true;
      this.organizations = response.data.organizations;
    }
  }

  async handleRowClick(id: string) {
    const rows = document.querySelectorAll('.organization-table tr');
    for (let i = 0; i < rows.length; i += 1) {
      rows[i].classList.remove('selected');
    }
    const tableRow = document.querySelector(`.organization-table tr[data-id="${id}"]`);
    if (tableRow) {
      tableRow.classList.add('selected');
    }

    try {
      const response = await this.$utils.http().post('/api/v1/access/organizations', {
        organization: id,
      });

      if (response.data.success) {
        this.organizations = this.organizations.map((org) => ({
          ...org,
          selected: org._id === id,
        }));
        this.refreshWhereverConfiguration();
      }
    } catch (e) {
      const currentOrganization = this.organizations.find((o) => o._id === id);
      currentOrganization.selected = true;
    }

    this.$emit('rowClick', id);
    this.$router.push(`/organizations/${id}`);
  }

  async refreshWhereverConfiguration() {
    try {
      const response = await this.$utils.http().get('/api/v1/config');
      this.setConfig(response.data);
    } catch (e) {
      this.setConfig(null);
    }
  }

  mounted() {
    this.getorganizations();
  }
}
