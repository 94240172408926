<template>
  <grid>
    <grid-col size="6" v-if="user">
      <card>
        <h4 class="card-title">
          <span v-if="userCan('edit', 'organization')">Modify</span>
          <span v-else>View</span>
          &quot;{{ user.firstname }} {{ user.lastname }}&quot;</h4>
        <div class="properties">
          <div class="property">
            <div class="title-label">First name</div>
            <input
              type="text"
              placeholder="First name"
              class="input-text"
              v-model="userToEdit.firstname"
              :disabled="!userCan('edit', 'organization')"
            />
          </div>
          <div class="property">
            <div class="title-label">Last name</div>
            <input
              type="text"
              placeholder="Last name"
              class="input-text"
              v-model="userToEdit.lastname"
              :disabled="!userCan('edit', 'organization')"
            />
          </div>
          <div class="property">
            <div class="title-label">E-mail</div>
            <input
              type="text"
              placeholder="fox.mulder@fbi.gov"
              class="input-text"
              v-model="userToEdit.email"
              :disabled="!userCan('edit', 'organization')"
            />
          </div>
          <div class="property">
            <div class="title-label">Login</div>
            <input
              type="text"
              placeholder="fox.mulder@fbi.gov"
              class="input-text"
              v-model="userToEdit.login"
              :disabled="!userCan('edit', 'organization')"
            />
          </div>
          <div class="property" v-if="userCan('edit', 'organization')">
            <div class="title-label">New password</div>
            <input
              type="text"
              placeholder="t0ps3cr3t"
              class="input-text"
              v-model="userToEdit.password"
            />
          </div>
          <div v-if="isAdmin()" class="property">
            <div class="title-label">Admin</div>
            <input type="checkbox" class="input-text" v-model="userToEdit.admin" />
          </div>
          <div v-if="isAdmin() && user.two_factor_secret" class="property">
            <div class="title-label">Reset 2FA</div>
            <input type="checkbox" class="input-text" v-model="userToEdit.reset2fa" />
          </div>
          <div class="property" v-if="userCan('edit', 'organization')">
            <base-button isSmall iconType="PLUS" @click="saveUser" title="Save changes" />
            <base-button
              isSmall
              type="THEMED"
              iconType="CANCEL"
              @click="cancelEdit"
              title="Cancel"
            />
            <base-button
              v-if="
                isAdmin() ||
                (!isAdmin() && !user.admin && getUser().id.toString() !== user._id.toString())
              "
              type="THEMED_DELETE"
              class="right"
              iconType="TRASH"
              @click="deleteUser"
              title="Delete"
            />
          </div>
        </div>
      </card>
    </grid-col>
    <grid-col size="6" v-show="isAdmin() || userCan('view', 'acl')">
      <card title="Access control">
        <AccessControl v-model="access" :edituserid="user._id" />
      </card>
    </grid-col>
  </grid>
</template>

<script>
import Utils from '@/utils';
import AccessControl from './AccessControl.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'EditUser',
  props: ['user', 'orgId'],
  data() {
    return {
      access: null,
      userToEdit: { ...this.user },
    };
  },
  components: {
    Card,
    AccessControl,
    Grid,
    GridCol,
    BaseButton,
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async saveUser() {
      const url = `/api/v1/organizations/${this.orgId}/user/${this.user._id}`;
      const response = await Utils.fetch(
        url,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: this.userToEdit,
            access: this.access,
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.afterEdit();
        this.$noty.success('The user has been saved!');
      } else {
        this.$noty.error(response.message);
      }
    },
    async deleteUser() {
      const url = `/api/v1/organizations/${this.orgId}/user`;
      const response = await Utils.fetch(
        url,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: this.user._id,
          }),
        },
        this,
      ).then((res) => res.json());

      this.afterEdit();

      if (response.success) {
        this.$noty.success('The user has been deleted!');
      } else {
        this.$noty.error('The user has NOT been deleted!');
      }
    },
    isAdmin() {
      return Utils.isAdmin();
    },
    cancelEdit() {
      this.afterEdit();
    },
    afterEdit() {
      this.$emit('afterEdit');
    },
    getUser() {
      return Utils.getUser();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
