import { render, staticRenderFns } from "./Read.vue?vue&type=template&id=4e5b292c&scoped=true&"
import script from "./Read.vue?vue&type=script&lang=js&"
export * from "./Read.vue?vue&type=script&lang=js&"
import style0 from "./Read.vue?vue&type=style&index=0&id=4e5b292c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5b292c",
  null
  
)

export default component.exports