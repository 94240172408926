<template>
  <grid>
    <grid-col size="6">
      <card title="Create new API-token">
        <div class="properties">
          <div class="property">
            <div class="title-label">Name</div>
            <input type="text" placeholder="Token name" class="input-text" v-model="token.name" />
          </div>
          <div class="property">
            <div class="title-label">Token Role</div>
            <select placeholder="Token role" v-model="token.aclRole" class="input-text">
              <option v-for="role in aclRoles" :key="role" :value="role">
                {{ role }}
              </option>
            </select>
          </div>
          <div class="property">
            <base-button v-on:click="createToken" title="Save" />
            <base-button v-on:click="cancelEdit" type="THEMED" iconType="CANCEL" title="Cancel" />
          </div>
        </div>
      </card>
    </grid-col>
  </grid>
</template>

<script>
import Vue from 'vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default Vue.extend({
  name: 'CreateUser',
  components: {
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['user', 'orgId'],
  data() {
    return {
      token: {
        name: '',
        aclRole: '',
      },
      aclRoles: [],
    };
  },
  methods: {
    async createToken() {
      try {
        const response = await this.$utils.http().post('/api/v1/api-tokens', this.token);
        this.$noty.success('The token has been created!');
        this.$router.push(`/organizations/${this.orgId}/token/${response.data._id}`);
      } catch (err) {
        this.$noty.error(`Could not create token: ${err.response.data.message}`);
      }
    },
    async getAclRoles() {
      const response = await this.$utils.http().get('/api/v1/api-tokens/roles');
      this.aclRoles = response.data;
    },
    afterEdit() {
      this.$emit('afterEdit');
    },
    cancelEdit() {
      this.afterEdit();
    },
  },
  mounted() {
    this.getAclRoles();
  },
});
</script>
