<template>
  <div class="col-12">
    <card title="Users">
      <div class="user-list">
        <div v-for="user in users" :key="user._id">
          <div class="user-link" v-if="userCan('view', 'organization')">
            <router-link :to="'/organizations/' + orgId + '/user/' + user._id">
              <i class="uil uil-user" />
              {{ user.firstname }} {{ user.lastname }}
            </router-link>
          </div>
        </div>
        <div class="user-link" v-if="userCan('edit', 'organization')">
          <router-link :to="'/organizations/' + orgId + '/user/new'">
            <i class="uil uil-plus-circle" />
            Create new user
          </router-link>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';

export default {
  name: 'ListUsers',
  props: ['users', 'orgId'],
  components: {
    Card,
  },
  data() {
    return {};
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    isAdmin() {
      return Utils.isAdmin();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.user-link a {
  text-decoration: none;
  color: white;

  i {
    margin-right: 6px;
  }

  &:hover {
    text-decoration: underline;
  }
}
</style>
