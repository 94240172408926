
import Vue from 'vue';
import Card from '../Card/Card.vue';
import { BearerToken } from '../../models/BearerToken';

type BearerTokenMixin = {
  shortToken: string;
};

export default Vue.extend({
  name: 'ListTokens',
  components: {
    Card,
  },
  props: {
    orgId: String,
  },
  data() {
    return {
      tokens: [] as (BearerToken & BearerTokenMixin)[],
    };
  },
  methods: {
    async listTokens() {
      const response = await this.$utils.http().get('/api/v1/api-tokens');
      this.tokens = (response.data as BearerToken[]).map((tokenRecord) => {
        const withShortToken: BearerToken & BearerTokenMixin = {
          ...tokenRecord,
          shortToken: `${tokenRecord.token.substring(0, 5)}...${tokenRecord.token.substring(
            tokenRecord.token.length - 5,
          )}`,
        };

        return withShortToken;
      });
    },
  },
  mounted() {
    this.listTokens();
  },
});
